import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layouts/Default"
import Breadcrumb from "../../components/common/Breadcrumb"
import Container from "../../components/layouts/container/Container"
import HeaderImage from "../../components/common/HeaderImage"
import Cta from "../../components/common/Cta"

export default function Page({ data, location }) {
  const svg = {
    phone: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-16 h-16 mx-auto text-brand"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1}
          d="M21 3l-6 6m0 0V4m0 5h5M5 3a2 2 0 00-2 2v1c0 8.284 6.716 15 15 15h1a2 2 0 002-2v-3.28a1 1 0 00-.684-.948l-4.493-1.498a1 1 0 00-1.21.502l-1.13 2.257a11.042 11.042 0 01-5.516-5.517l2.257-1.128a1 1 0 00.502-1.21L9.228 3.683A1 1 0 008.279 3H5z"
        />
      </svg>
    ),
    mail: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-16 h-16 mx-auto text-brand"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1}
          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
        />
      </svg>
    ),
    location: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-16 h-16 mx-auto text-brand"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1}
          d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1}
          d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
        />
      </svg>
    ),
  }

  return (
    <Layout
      location={location}
      title="Wertermittlung | Döbbel Immobilien"
      name="Wertermittlung"
      desc=""
    >
      <HeaderImage img={data.header.childImageSharp.fluid} />
      <Breadcrumb
        links={[
          { link: "Leistungen", to: "/leistungen/" },
          {
            link: "Wertermittlung",
            to: "/leistungen/wertermittlung/",
          },
        ]}
      />
      <Container className="my-8 md:my-16">
        <iframe
          src="https://www.immobilien-wertermittlung.de/iwlead/lead?uid=7076&c=da4y6g5vl2c33r4y"
          style={{
            height: "75vh",
            minHeight: 580,
            background: "#FFFFFF",
            width: "100%",
          }}
          frameBorder={0}
        />
      </Container>
      <Container className="my-8 md:my-16">
        <Cta />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    header: file(relativePath: { eq: "vermarktung.jpg" }) {
      childImageSharp {
        fluid(jpegQuality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        contact {
          city
          phone
          plz
          street
          mail
        }
      }
    }
  }
`
